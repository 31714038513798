<template>
  <div class="devcomp">
    <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" class="demo-form-inline">

            <el-form-item label="  ">
              <el-input style="width: 360px" placeholder="支持模糊搜索" v-model="searchsn"
                        maxlength="15" clearable @clear="onRefresh()" @keyup.enter.native="onRefresh()">
                <template slot="prepend">串号搜索：</template>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search"></el-button>
              </el-input>
            </el-form-item>

            <el-form-item label="  ">
              <el-input style="width: 320px" placeholder="支持模糊搜索" v-model="searchname"
                        maxlength="15" clearable @clear="onRefresh()" @keyup.enter.native="onRefresh()">
                <template slot="prepend">设备名搜索：</template>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search"></el-button>
              </el-input>
            </el-form-item>

            <el-form-item label="服务器筛选：">
              <el-select clearable filterable @change="onRefresh"  v-model="ssvalue" placeholder="请选择" style="width: 150px" >
                <el-option v-for="item in sselect" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>


            <el-form-item label="  ">
              <el-checkbox v-model="reverseFlag" @change="onRefresh">按上线时间排序</el-checkbox>
            </el-form-item>

            <el-form-item label="  ">
              <el-button type="success" @click="onRefresh" icon="el-icon-search">查找设备</el-button>
            </el-form-item>



          </el-form>

        </div>
        <div class="search">


        </div>

        <el-table :data="tbdata" border stripe  style="width: 100%; margin: 3px">


          <el-table-column prop="server" align="center" label="服务器" width="125">
          </el-table-column>


          <el-table-column prop="sn" align="center" label="设备IMEI" width="145">
          </el-table-column>

          <el-table-column prop="na" align="center" label="设备名" width="165">
          </el-table-column>

          <el-table-column prop="cid" align="center" label="设备归属" width="165">
          </el-table-column>


          <el-table-column prop="iccid" align="center" label="设备卡号" width="195">
          </el-table-column>


          <el-table-column prop="loc" label="设备定位" align="center">
          </el-table-column>

          <el-table-column prop="update_time" align="center" label="最后上线时间" width="170">
          </el-table-column>

        </el-table>


      </el-col>


    </el-row>


  </div>
</template>

<script>
export default {
  data() {
    return {
      searchsn:"",//按SN查找
      searchname:"",//按name查找
      reverseFlag:false,
      tbdata: [],
      sselect: [{
        value: '',
        label: '不限'
      },

    ],
      ssvalue: '',



    }
  },
  created() {
    this.info = this.$store.state.userInfo
    this.onServerTypeList()
  },
  mounted() {

  },
  beforeDestroy() {
  },
  methods: {

    //////////////++++++++++++++//////////  excel //++++++++++++++++++++++//////////////////

    //////
    // 主刷新
    async onRefresh() {
      let res = await this.$api.device_list_v9({
        sn:this.searchsn+"",
        name:this.searchname+"",
        serv: this.ssvalue+"",
        sorted:this.reverseFlag,
      })
      console.log(res)
      if (res.status == 1) {
        this.tbdata = res.data
      }
    },

    async onServerTypeList() {
      let res = await this.$api.server_list()
      console.log(res)
      if (res.status == 1) {
        if (res.u != null)
          this.sselect = [{
            value: '',
            label: '不限'
          }, ...res.u]
      }
    },

    countFormatter(row, column, cellValue) {
      return cellValue + '次';
    },


  },
}
</script>

<style scoped>
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.detail-card .el-button {
  margin: 8px;
  margin-bottom: 14px;
  font-size: 16px;
  width: 92%
}
</style>
